const Localization = {
    Languages: {
        EN_CA: 'en-CA',
        EN_US: 'en-US'
    },
    Namespaces: {
        CORE: 'core',
        COMMON: 'common',
        BANKING_RECONCILIATION: 'banking-reconciliation'
    }
};

export default Localization;