import React, { ReactElement, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';

import DeadEnd from '@lwt-helix/dead-end';

import { useStateApi } from '../../hooks/state';
import {
    AuthenticationState,
    AuthenticationStateApi,
    authenticationStateApiFactory
} from './authenticationStateApiFactory';
import { useAppStore } from '../../stores/appStore';
import Routes from '../../routes/Routes';
import { useTranslation } from 'react-i18next';
import Constants from '../../constants';
import { getUrlParameter } from '../../helpers/routeHelpers';
import { ReadOnlyAppContextInterface } from '../../types/readonlyAppContext';
import { ReadonlyAppContext } from '../app/App';

interface JwtPayload {
    name?: string;
    given_name?: string;
    family_name?: string;
    email?: string;
    'http://lwt': { [key: string]: string };
}

const parseJwt = (token: string): JwtPayload => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const Authentication = (): ReactElement | null => {
    const { user: { token }, setBwInstanceId, setClientId, setUser, setLocale } = useAppStore();

    const {
        config: {
            bankingReconciliation: {
                homeUI: { redirectUrl }
            }
        }
    } = useContext<ReadOnlyAppContextInterface>(ReadonlyAppContext);

    const { t } = useTranslation(Constants.Localization.Namespaces.CORE);

    const stateApi = useStateApi<AuthenticationState, AuthenticationStateApi>(
        authenticationStateApiFactory,{}
    );

    const deleteTokenCookie = (): void => {
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite: None; Secure';
    };

    const getTokenCookie = (): string => {
        return document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
    };

    const setLocaleAndInstanceId = (): void => {
        const bwInstanceId = document.cookie
            .replace(/(?:(?:^|.*;\s*)bwInstanceId\s*=\s*([^;]*).*$)|^.*$/, '$1');

        // Delete cookie
        document.cookie = 'bwInstanceId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        setBwInstanceId(bwInstanceId);

        // *** remove this before deployment ****
        // document.cookie = 'bwLocale=en-CA; expires=Thu, 01 Jan 2070 00:00:00 UTC; path=/;';

        const bwLocale = document.cookie
            .replace(/(?:(?:^|.*;\s*)bwLocale\s*=\s*([^;]*).*$)|^.*$/, '$1');

        // Delete cookie
        document.cookie = 'bwLocale=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        setLocale(bwLocale);
    };

    const getToken = (): string => {
        const tokenParam = getUrlParameter('access_token');
        if (tokenParam && tokenParam.length > 0) {
            return tokenParam;
        }
        return getTokenCookie();
    };

    const setSessionCookie = (cookieName: string, cookieValue: string): void =>  {
        document.cookie = `${cookieName}=${cookieValue}; path=/; SameSite: None;  Secure`;
    };

    useEffect((): void => {
        // *** remove this before deployment ****
        // Admin jwt
        // eslint-disable-next-line max-len
        // const jwt = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFqaEVRemMyTnpnMk1VTkdRVEUyUlRReE56ZERSVVF4UVRVNVFUZzJOa1UzUWtNd1FqVTFOZyJ9.eyJodHRwOi8vbHd0Ijp7InVpZCI6IjkzMmY5OWFlLWU4MDktNDNiOS1hYTEzLWJkYTJiN2M5Mjk5YyIsImFwcHMiOnsiYm8iOnsiY2lkIjoiNDA4QlIxIiwibWlkIjoiMzc2NUNGNUEtMzRBRi00MDg5LUIyNkUtQjBGQzFBQUEyMTVGIiwib2lkIjoiRERFNDY5MEQtM0JCMS00RjVELTkyNDYtMUFGOUQ0MjlEMzc0In19LCJjaWQiOiI0MDhCUjEiLCJtaWQiOiIzNzY1Q0Y1QS0zNEFGLTQwODktQjI2RS1CMEZDMUFBQTIxNUYiLCJvaWQiOiJEREU0NjkwRC0zQkIxLTRGNUQtOTI0Ni0xQUY5RDQyOUQzNzQifSwiZ2l2ZW5fbmFtZSI6IkpvbkRvZSIsImZhbWlseV9uYW1lIjoiQWRtaW5pc3RyYXRvciIsIm5pY2tuYW1lIjoiNDA4YnIxLWFkbWluaXN0cmF0b3IiLCJuYW1lIjoiNDA4YnIxLWFkbWluaXN0cmF0b3JAdXNlcnMubHdvbGYuY29tIiwicGljdHVyZSI6Imh0dHBzOi8vcy5ncmF2YXRhci5jb20vYXZhdGFyLzBiNDQwMmQzNzAzODlmOTAzMTA3MWNkOGVhYWNlMzA1P3M9NDgwJnI9cGcmZD1odHRwcyUzQSUyRiUyRmNkbi5hdXRoMC5jb20lMkZhdmF0YXJzJTJGNDAucG5nIiwidXBkYXRlZF9hdCI6IjIwMjItMTEtMjlUMTY6Mjk6NTcuODA3WiIsImVtYWlsIjoiNDA4YnIxLWFkbWluaXN0cmF0b3JAdXNlcnMubHdvbGYuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOi8vbHd0LWRldi5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjFmNzA2NzIxZThiNGEwMDcyYWU4MGU4IiwiYXVkIjoiRWpxbXNHM0VPeG9GODVIRndUZkgzSDl1NTZsZW9TQlgiLCJpYXQiOjE2Njk3MzkzOTcsImV4cCI6MTY2OTgyNTc5N30.osQy3v3QDSfWtkcCuS55PJoJMePk1xjJa7GY9obBVBtcYd7s-gdKYgwC7pEmauo4usNqUHkxgqbw9TFTo0XmqHh5fBzJNlDcpP3pIKFW31T8Oaf-NDfSaltpuRnWP3PhnodPSAj5kzi2cXyv8Il_4YaOXRbBocBhKuus5xancO3kHOPwx5qpsy9MJrkMlxeCxTmEXegZyxhKU7D12lON9jHn98txqc_1c3go8Jnex8jJPVZaRfejdeTNPfYJL4kwV1pS5xUYzNO12Ecb4q9bUZNHpDQ9Z6Y79K1fUBLR6hMptdpDu_vXZnx-xxlfuHFNnoGJZeaD1jehgHRYhUytfg';
        // document.cookie = `token=${jwt}; expires=Thu, 01 Jan 2070 00:00:00 UTC; path=/;`;

        // Limited Admin jwt
        // eslint-disable-next-line max-len
        // const jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiR3VzIEdyaXNzb20iLCJpc3MiOiJodHRwczovL2x3dC5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NTk0ODBlZjE3MWE3NDFkY2QwODQ3ZDNhIiwiYXVkIjoiRndUZkgzSDl1NTZsZW9TQlhFanFtc0czRU94b0Y4NUgiLCJodHRwOi8vbHd0Ijp7ImNpZCI6IlBSRDUwMCIsIm9pZCI6ImZiOTUwNmRjLTkwNGQtNDFiNy1iZWIwLWJiMzQ4NDA2YTYzYyIsInVpZCI6IjU4NzhhYzc5LTc2NjYtNDFkYi05OGNlLWYwNmY5ZjI2NGZkNiJ9fQ.GFb3VSn4vnu1RKGiItakOnjrMv1N1vlmO1P7eh3pQB5'
        // document.cookie = `token=${jwt}; expires=Thu, 01 Jan 2070 00:00:00 UTC; path=/;`;

        const token = getToken();
        if (!token || token.length == 0) {
            window.location.href = redirectUrl;
            return;
        }
        deleteTokenCookie();
        setSessionCookie('token', token);

        if (token) {
            setLocaleAndInstanceId();
            const jwtPayload = parseJwt(token);

            setClientId(jwtPayload['http://lwt'].cid);

            const officeId = jwtPayload['http://lwt'].oid;
            const userId = jwtPayload['http://lwt'].uid;
            const memberId = jwtPayload['http://lwt'].mid;

            setUser({
                officeId: officeId,
                firstName: jwtPayload.given_name,
                lastName: jwtPayload.family_name,
                email: jwtPayload.email,
                userId: userId,
                memberId: memberId,
                fullName: jwtPayload.name,
                token: token
            });

            stateApi.setAuthenticated(true);
        } else {
            stateApi.setAuthenticated(false);
        }
    }, []);

    return stateApi.authenticated !== undefined && (
        <>
            {
                stateApi.authenticated
                    ? token && <Routes />
                    : (
                        <DeadEnd
                            message={
                                <h2 className="mb-3">
                                    {
                                        t('authentication.unauthorized')
                                    }
                                </h2>
                            }
                        />
                    )
            }
        </>
    ) || null;
};

export default withRouter(Authentication);
