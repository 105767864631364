import React, { DependencyList, ReactElement, useEffect, useRef } from 'react';
import { useAppStore } from '../stores/appStore';
import { useTranslation } from 'react-i18next';
import Constants from '../constants';
import { Button } from '@lwt-helix/buttons';

export const useIsFirstRender = (): boolean => {
    const isFirstRender = useRef(true);

    useEffect(
        () => {
            isFirstRender.current = false;
        },
        []
    );

    return isFirstRender.current;
};

export const useCancelableEffect = (
    effect: (cleanup: { didCancel: boolean }) => void,
    deps?: DependencyList,
    cleanupCallBack?: () => void
): void => {
    useEffect((): (() => void) => {
        /**
         * This cleanup object addresses the React warning below:
         *
         * "Warning: Can't perform a React state update on an unmounted component.
         * This is a no-op, but it indicates a memory leak in your application.
         * To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function."
         *
         * It is an object and not just a boolean variable, because we are passing it into effect(...),
         * so we need an object in order to pass it by reference.
         *
         * ALWAYS CHECK !cleanup.didCancel INSIDE THE effect CALLBACK FUNCTION BODY, BEFORE EACH UPDATE TO LOCAL STATE
         */
        const cleanup = {
            didCancel: false
        };

        effect(cleanup);

        return (): void => {
            cleanup.didCancel = true;
            cleanupCallBack && cleanupCallBack();
        };
    }, deps);
};

export const useConfirmModal = (
    message: string | ReactElement,
    saveCallback: () => void,
    discardCallback?: () => void,
    buttonLabels?: {
        saveButton?: string;
        discardButton?: string;
        cancelButton?: string;
    },
): ((message?: string | ReactElement) => void) => {
    const { showModal, dismissModal } = useAppStore();

    const { t } = useTranslation(Constants.Localization.Namespaces.CORE);

    const modalProps = {
        message: message,
        buttons:
            <div id="confirm-modal">
            <Button
                dataLwtId="modal-save-button"
                id="modal-save-button"
                onClick={(): void => {
                    saveCallback();
                    dismissModal();
                }}
            >
                {
                    buttonLabels && buttonLabels.saveButton ? buttonLabels.saveButton : t('ui.buttons.save')
                }
            </Button>
            {
                discardCallback &&
                <Button
                    dataLwtId="modal-discard-button"
                    id="modal-discard-button"
                    onClick={(): void => {
                        discardCallback();
                        dismissModal();
                    }}
                >
                {
                    buttonLabels && buttonLabels.discardButton ? buttonLabels.discardButton : t('ui.buttons.discard')
                }
                </Button>
            }
            <Button
                dataLwtId="modal-cancel-button"
                id="modal-cancel-button"
                onClick={dismissModal}
            >
                {
                    buttonLabels && buttonLabels.cancelButton ? buttonLabels.cancelButton : t('ui.buttons.cancel')
                }
            </Button>
        </div>
    };

    return (message?: string | ReactElement): void => {
        if (message) {
            modalProps.message = message;
        }

        showModal(modalProps);
    };
};