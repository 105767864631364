/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';

import { ModalProps } from '@lwt-helix/modal';
import { FormSchemaValidationErrors } from '@lwt-helix/form-schema';
import { ToastProps } from '@lwt-helix/toast';

import createStore from './createStore';
import  TagManager from 'react-gtm-module';

import {
    AppStore,
    AppStoreApi, Office,
    SlideoutPanelExtendedProps,
    User
} from '../types/appStore';
import { setStateData } from '../helpers/state';
import { Permissions, Role } from '../types/permissions';
import { SlideoutPanelStoreProvider } from './slideoutPanelStore';
import { StateApiFactory } from '../types/state';
import { Brokerage } from '../types/roster';

const { StoreProvider: AppStoreProvider, useStore: useAppStore } = createStore<AppStore, AppStoreApi<any, any>>(
    ({ state, setState }): AppStoreApi<any, any> => {
        const setBwInstanceId = (bwInstanceId?: string | null): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    bwInstanceId: bwInstanceId,
                    isStandAlone: !bwInstanceId,
                    layout: {
                        fluid: !!bwInstanceId
                    }
                })
            );
        };

        const setClientId = (clientId: string): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    clientId: clientId
                })
            );
        };

        const setLocale = (culture: string): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    locale: culture
                })
            );
        };

        const dismissModal = (): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    modal: {
                        show: false
                    }
                })
            );
        };

        const showModal = ({ ...props }: ModalProps | undefined): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    modal: {
                        ...props,
                        show: true,
                        onClose: props.onClose || dismissModal
                    }
                })
            );
        };

        const showToast = ({ ...props }: ToastProps | undefined): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    toast: {
                        ...props
                    }
                })
            );
        };

        const toggleSlideoutPanel = <S, A>(
            props: SlideoutPanelExtendedProps<S, A>,
            doubleClickClose?: boolean
        ): void => {
            const { storeApiFactory, ...slideoutPanelProps } = props;

            setState(
                (prevState: AppStore): AppStore => {
                    const nextState = { ...prevState };

                    const initialProps = {
                        dataLwtId: 'slideout-panel'
                    };

                    if (!slideoutPanelProps.contentId && nextState.slideoutPanel.cachedProps) {
                        nextState.slideoutPanel = {
                            ...prevState.slideoutPanel,
                            ...nextState.slideoutPanel.cachedProps,
                            cachedProps: undefined,
                            beforeChange: undefined,
                            body: nextState.slideoutPanel.cachedProps.storeApiFactory
                                ? (
                                    <SlideoutPanelStoreProvider
                                        storeApiFactory={nextState.slideoutPanel.cachedProps.storeApiFactory}
                                    >
                                        {nextState.slideoutPanel.cachedProps.body}
                                    </SlideoutPanelStoreProvider>
                                )
                                : slideoutPanelProps.body,
                            onClose: (): boolean => {
                                toggleSlideoutPanel({});
                                return false;
                            }
                        };
                    } else if (!slideoutPanelProps.contentId ||
                        (slideoutPanelProps.contentId === prevState.slideoutPanel.contentId && doubleClickClose)
                    ) {
                        nextState.slideoutPanel = initialProps;
                    } else if (slideoutPanelProps.contentId !== prevState.slideoutPanel.contentId &&
                        prevState.slideoutPanel.beforeChange && !prevState.slideoutPanel.beforeChange()) {
                        nextState.slideoutPanel.cachedProps = {
                            ...initialProps,
                            ...slideoutPanelProps,
                            storeApiFactory
                        };
                    } else {
                        nextState.slideoutPanel = {
                            ...initialProps,
                            ...slideoutPanelProps,
                            body: storeApiFactory
                                ? (
                                    <SlideoutPanelStoreProvider storeApiFactory={storeApiFactory}>
                                        {slideoutPanelProps.body}
                                    </SlideoutPanelStoreProvider>
                                )
                                : slideoutPanelProps.body,
                            toggleBit: true,
                            cachedProps: undefined
                        };
                    }

                    return nextState;
                }
            );
        };

        const setFormValidationErrors = (errors: FormSchemaValidationErrors): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    formValidationErrors: errors
                })
            );
        };

        const setWindowDimensions = (dimensions: {
            width: number;
            height: number;
        }): void => {
            setState(
                (prevState: AppStore): AppStore => {
                    return {
                        ...prevState,
                        window: {
                            ...prevState.window,
                            dimensions: dimensions
                        }
                    };
                }
            );
        };

        const setUser = (user: User): void => {
            setStateData('user', user, setState);
        };

        const setOffice = (setPendoOffice: Office): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                   office: setPendoOffice
                })
            );
        };

        const setBrokerage = (setPendoBrokerage: Brokerage): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    brokerage: setPendoBrokerage
                })
            );
        };

        const cachePermissions = (key: string, role: Role): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    permissions: {
                        ...prevState.permissions,
                        [key]: role.permissions
                    },
                    roleName: role.roleName,
                    controlDateStart: role.controlDateStart,
                    controlDateEnd: role.controlDateEnd
                })
            );
        };

        const clearPermissions = (key: string): void => {
            setState(
                (prevState: AppStore): AppStore => {
                    const permissions: Permissions = { ...prevState.permissions };

                    delete permissions[key];

                    return {
                        ...prevState,
                        permissions: permissions
                    };
                }
            );
        };

        const setModuleStoreApiFactory = (
            route: string,
            moduleStoreApiFactory: StateApiFactory<any, any>
        ): void => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setState(prevState => ({
                ...prevState,
                moduleRoute: route,
                moduleStoreApiFactory,
                subModuleRoute: undefined,
                subModuleStoreApiFactory: () => ({}),
                domainRoute: undefined,
                domainStoreApiFactory: () => ({})
            }));
        };

        const setSubModuleStoreApiFactory = (
            route: string,
            subModuleStoreApiFactory: StateApiFactory<any, any>
        ): void => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setState(prevState => ({
                ...prevState,
                subModuleRoute: route,
                subModuleStoreApiFactory,
                domainRoute: undefined,
                domainStoreApiFactory: () => ({})
            }));
        };

        const setDomainStoreApiFactory = (
            route: string,
            domainStoreApiFactory: StateApiFactory<any, any>
        ): void => {
            setState(prevState => ({
                ...prevState,
                domainRoute: route,
                domainStoreApiFactory
            }));
        };

        const setLayoutFluidity = (fluid: boolean): void => {
            setState(
                (prevState: AppStore): AppStore => ({
                    ...prevState,
                    layout: {
                        ...prevState.layout,
                        fluid
                    }
                })
            );
        };

        const isStandAlone = state.isStandAlone;
        const bwInstanceId = state.bwInstanceId;
        const clientId = state.clientId;
        const user = state.user;
        const office = state.office;
        const brokerage = state.brokerage;
        const permissions = state.permissions;
        const roleName = state.roleName;
        const controlDateStart = state.controlDateStart;
        const controlDateEnd = state.controlDateEnd;
        const locale = state.locale;
        const modal = state.modal;
        const slideoutPanel = state.slideoutPanel;
        const formValidationErrors = state.formValidationErrors;
        const window = state.window;
        const moduleRoute = state.moduleRoute;
        const subModuleRoute = state.subModuleRoute;
        const domainRoute = state.domainRoute;
        const moduleStoreApiFactory = state.moduleStoreApiFactory;
        const subModuleStoreApiFactory = state.subModuleStoreApiFactory;
        const domainStoreApiFactory = state.domainStoreApiFactory;
        const layout = state.layout;
        const toast = state.toast;

        TagManager.dataLayer({
                dataLayer: {
                    user: {
                        id: user.userId,
                        first_name: user.firstName,
                        last_name: user.lastName,
                        email: user.email,
                        role: roleName,
                    },

                    client: {
                        id: brokerage.id,
                        client_name: brokerage.name
                    },
                    office,
                },
            });

        return {
            isStandAlone,
            bwInstanceId,
            clientId,
            user,
            office,
            brokerage,
            permissions,
            roleName,
            controlDateStart,
            controlDateEnd,
            locale,
            modal,
            slideoutPanel,
            formValidationErrors,
            window,
            moduleRoute,
            subModuleRoute,
            domainRoute,
            moduleStoreApiFactory,
            subModuleStoreApiFactory,
            domainStoreApiFactory,
            layout,
            toast,
            setBwInstanceId,
            setClientId,
            setUser,
            setOffice,
            setBrokerage,
            setLocale,
            showModal,
            dismissModal,
            toggleSlideoutPanel,
            setFormValidationErrors,
            setWindowDimensions,
            cachePermissions,
            clearPermissions,
            setModuleStoreApiFactory,
            setSubModuleStoreApiFactory,
            setDomainStoreApiFactory,
            setLayoutFluidity,
            showToast
        };
    },
    {
        isStandAlone: false,
        locale: 'en-CA',
        user: {},
        modal: {
            show: false
        },
        layout: {
            fluid: false
        },
        slideoutPanel: {
            dataLwtId: 'slideout-panel',
            contentId: undefined
        },
        window: {
            dimensions: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        },
        office: {},
        brokerage: {}
    },
    (store: AppStoreApi<any, any>): (() => void) => {
        const handleResize = (): void => {
            store.setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }
);

export { AppStoreProvider, useAppStore };
