import React, { createContext, ReactElement, useEffect, useState } from 'react';

import Page from '@lwt-helix/page';
import Modal from '@lwt-helix/modal';
import SlideoutPanel from '@lwt-helix/slideout-panel';
import { ToastContainer } from '@lwt-helix/toast';

import Authentication from '../authentication/Authentication';
import Config from '../../helpers/config';
import { useAppStore } from '../../stores/appStore';
import { ReadOnlyAppContextInterface } from '../../types/readonlyAppContext';
import useI18n from '../../hooks/localization';
import { CommonStoreProvider } from '../../../modules/common/stores/commonStore';
import { ModuleStoreProvider } from '../../stores/moduleStore';
import { SubModuleStoreProvider } from '../../stores/subModuleStore';
import { DomainStoreProvider } from '../../stores/domainStore';
import { Navbar, NavbarAdditionalItems, NavbarUserMenu } from '@lwt-helix/navbar';
import Constants from '../../constants';

require('lodash/isEqual');

export const ReadonlyAppContext = createContext<ReadOnlyAppContextInterface>({
    config: {
        ...(Config.get()),
    }
});

const App = (): ReactElement => {
    const [isAppReady, setIsAppReady] = useState(false);

    const {
        isStandAlone,
        user: { token },
        modal: modalProps,
        toast: toastProps,
        slideoutPanel: slideoutPanelProps,
        moduleStoreApiFactory,
        subModuleStoreApiFactory,
        domainStoreApiFactory,
        layout
    } = useAppStore();

    const initI18n = useI18n();

    useEffect((): (() => void) => {
        let didCancel = false;

        !didCancel && initI18n();

        !didCancel && setIsAppReady(true);

        return (): void => {
            didCancel = true;
        };
    }, []);

    return (
        <Page fluid={layout.fluid}>
            {
                isAppReady && isStandAlone &&
                (
                    <>
                        <Navbar
                            dataLwtId="backoffice-nav-bar"
                            fixed={true}
                            isDark={false}
                            applicationName="Back Office Bank Reconciliation"
                            //eslint-disable-next-line max-len
                            logoUrl={`${Constants.Routes.Financial.MODULE}${Constants.Routes.Financial.BankingReconciliation.SUB_MODULE}${Constants.Routes.Financial.BankingReconciliation.RECONCILIATION_LANDING}/signin?access_token=${token}`}
                        >
                            <NavbarAdditionalItems>
                                <NavbarUserMenu
                                    menuItems={[
                                        {
                                            //eslint-disable-next-line max-len
                                            'url': `${Constants.Routes.Financial.MODULE}${Constants.Routes.Financial.BankingReconciliation.SUB_MODULE}${Constants.Routes.Financial.BankingReconciliation.SIGNOUT}`,
                                            'label': 'Return to LW Home'
                                        }
                                    ]}
                                />
                            </NavbarAdditionalItems>
                        </Navbar>
                        <br /><br />
                    </>
                )
            }
            <CommonStoreProvider>
                <ModuleStoreProvider storeApiFactory={moduleStoreApiFactory}>
                    <SubModuleStoreProvider storeApiFactory={subModuleStoreApiFactory}>
                        <DomainStoreProvider storeApiFactory={domainStoreApiFactory}>
                            <Modal {...modalProps} />
                            {
                                isAppReady &&
                                (
                                    <Authentication />
                                )
                            }
                            <SlideoutPanel {...slideoutPanelProps} pageHasNavbar={isStandAlone} />
                            {toastProps && <ToastContainer
                                toastProps={toastProps}
                                position="toast-bottom-left"
                                dataLwtId="toast-container"
                            />}
                        </DomainStoreProvider>
                    </SubModuleStoreProvider>
                </ModuleStoreProvider>
            </CommonStoreProvider>
        </Page>
    );
};

export default App;
