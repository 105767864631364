import { useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fetch from 'i18next-fetch-backend';

import { useAppStore } from '../stores/appStore';
import { ReadOnlyAppContextInterface } from '../types/readonlyAppContext';
import { ReadonlyAppContext } from '../components/app/App';
import Constants from '../constants';
import getFormValidationErrors from '../formValidationErrors';
import { useCancelableEffect } from './utils';

const currencyCodeMap: { [locale: string]: string } = {
    'en-CA': 'CAD',
    'en-US': 'USD'
};

const whiteList = [Constants.Localization.Languages.EN_US, Constants.Localization.Languages.EN_CA];
const fallBackLng = Constants.Localization.Languages.EN_CA;

const useI18n = (): () => void => {
    const { config: { localization: { debug } } } = useContext<ReadOnlyAppContextInterface>(ReadonlyAppContext);

    const { locale, showModal, setFormValidationErrors } = useAppStore();

    useCancelableEffect(
        (cleanup: { didCancel: boolean }): void => {

            !cleanup.didCancel && locale && i18n.isInitialized &&
                i18n.changeLanguage(whiteList.includes(locale) ? locale : fallBackLng);
        },
        [locale]
    );

    return (): void => {
        i18n
            // load translations using xhr. learn more: https://github.com/i18next/i18next-xhr-backend
            .use(fetch)
            // Detect user language. learn more: https://github.com/i18next/i18next-browser-languageDetector
            .use(LanguageDetector)
            // Pass the i18n instance to react-i18next. Enables use of the useTranslation hook.
            .use(initReactI18next)
            // Init i18next
            // For all options read: https://www.i18next.com/overview/configuration-options
            .init({
                load: 'currentOnly', // so i18next doesn't try to fetch 'en'
                whitelist: whiteList,
                fallbackLng: fallBackLng,

                ns: [Constants.Localization.Namespaces.CORE],
                defaultNS: Constants.Localization.Namespaces.COMMON,
                fallbackNS: Constants.Localization.Namespaces.CORE,

                debug: debug,

                backend: {
                    loadPath: `${location.protocol}//${location.host}/{{lng}}/{{ns}}.{{lng}}.json`
                },

                detection: {
                    order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag'],
                },

                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                    format: (value, format, lng): string => {
                        switch (format) {
                            case 'date':
                                // eslint-disable-next-line no-case-declarations
                                const date = new Date(value);
                                return Intl.DateTimeFormat(lng)
                                    .format(new Date(date.getTime() + date.getTimezoneOffset() * 60000));
                            case 'dateTime':
                                return Intl.DateTimeFormat(lng).format(new Date(value));
                            case 'fullDate':
                                return Intl.DateTimeFormat(lng, {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                }).format(new Date(value));
                            case 'currency':
                                return Intl.NumberFormat(lng, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(value);
                            case 'currencyWithSymbol':
                                return Intl.NumberFormat(lng, {
                                    style: 'currency',
                                    currency: currencyCodeMap[lng as string]
                                }).format(value);
                            case 'numeric':
                                return Intl.NumberFormat(lng).format(value);
                        }

                        return value;
                    }
                }
            })
            .then(() => {
                const t = i18n.getFixedT(i18n.language, Constants.Localization.Namespaces.CORE);

                setFormValidationErrors(getFormValidationErrors(t));

                locale && i18n.changeLanguage(whiteList.includes(locale) ? locale : fallBackLng);
            })
            // TODO: Create some default error (can't be localized with i18n)
            .catch(() => {
                showModal({
                    title: 'An error occurred',
                    message: 'some default error'
                });
            });
    };
};

export default useI18n;