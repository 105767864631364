import isEqual from 'lodash/isEqual';

import createStore from '../../../core/stores/createStore';
import { ValidationError } from '../types/validations';
import { setStateData } from '../../../core/helpers/state';

export interface CommonStore {
    errors: ValidationError[];
}

export interface CommonStoreApi extends CommonStore {
    setErrors: (errors: ValidationError[], append?: boolean) => void;
}

const { StoreProvider: CommonStoreProvider, useStore: useCommonStore } = createStore<CommonStore, CommonStoreApi>(
    ({ state, setState }): CommonStoreApi => {
        const setErrors = (errors: ValidationError[], append?: boolean): void => {
            if (append) {
                setState(prevState => {
                    const nextErrors = prevState.errors.map(error => ({ ...error }));

                    if (errors.length !== 0) {
                        errors.forEach(error => {
                            if (!nextErrors.some(previousErrors => isEqual(previousErrors, error))) {
                                nextErrors.push(error);
                            }
                        });
                    }

                    return {
                        errors: nextErrors
                    };
                });
            } else {
                setStateData('errors', errors, setState);
            }
        };

        const errors = state.errors;

        return {
            errors,
            setErrors
        };
    },
    {
        errors: []
    }
);

export { CommonStoreProvider, useCommonStore };
