module.exports = {
  "localization": {
    "debug": true
  },
  "api": {
    "authorization": {
      "baseUrl": "https://authorization.api.lwolf.com/v1",
      "refreshInterval": 3600000
    },
    "roster": {
      "baseUrl": "https://roster.api.lwolf.com/v1"
    },
    "banking": {
      "baseUrl": "https://bank-integration.api.lwolf.com/v1"
    },
    "xmb": {
      "baseUrl": "https://xmb.api.lwolf.com/v1"
    },
    "deals": {
      "baseUrl": "https://deals.api.lwolf.com/v1"
    },
    "reconciliations": {
      "baseUrl": "https://reconciliation.api.lwolf.com/v1"
    }
  },
  "oData": {
    "top": 100
  },
  "bankingReconciliation": {
    "landing": {
      "reconciliationsToDisplay": 3
    },
    "manageAccounts": {
      "operatingAccountsToDisplay": 10
    },
    "homeUI": {
      "redirectUrl": "https://home.lwolf.com"
    },
    "gtmId": "GTM-5C8PJTN"
  },
  "appInsights": {
    "connectionString": "InstrumentationKey=8e36c082-96ce-4918-8bc6-4ff72e0cf28f;IngestionEndpoint=https://eastus-6.in.applicationinsights.azure.com/",
    "appId": "prod-bw-web-ui"
  },
  "sonar": {
    "projectName": "prod-bw-web-ui"
  }
};