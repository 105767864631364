import { TFunction } from 'i18next';

import { FormSchemaValidationErrors } from '@lwt-helix/form-schema';

const getFormValidationErrors = (t: TFunction): FormSchemaValidationErrors => ({
    required: t('form.validationErrors.requiredField'),
    format: [
        {
            format: 'email',
            message: t('form.validationErrors.invalidEmail')
        },
        {
            format: 'password',
            message: t('form.validationErrors.invalidPassword')
        },
        {
            format: 'date',
            message: t('form.validationErrors.invalidDate')
        }
    ]
});

export default getFormValidationErrors;
