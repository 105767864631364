const Routes = {
    App: {
        DEAD_END: '/dead-end',
        LOGIN: '/login',
        REDIRECT_URI: '/redirect-uri'
    },
    Financial: {
        MODULE: '/financial',
        BankingReconciliation: {
            SUB_MODULE: '/banking-reconciliation',
            MANAGE_ACCOUNTS: '/manage-accounts',
            PREVIOUS_RECONCILIATION: '/previous-reconciliation',
            RECONCILE_ACCOUNT: '/reconcile-account',
            RECONCILE_HISTORY: '/reconcile-history',
            RECONCILIATION_LANDING: '/reconciliation-landing',
            SIGNOUT: '/signout'
        }
    }
};

export default Routes;