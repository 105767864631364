import { StateApiFactory } from '../../types/state';
import { setStateData } from '../../helpers/state';

export interface AuthenticationState {
    authenticated?: boolean;
}

export interface AuthenticationStateApi extends AuthenticationState {
    setAuthenticated: (authenticated: boolean) => void;
}

export const authenticationStateApiFactory: StateApiFactory<
    AuthenticationState,
    AuthenticationStateApi
> = ({ state, setState }): AuthenticationStateApi => {
    const setAuthenticated = (authenticated: boolean): void => {
        setStateData('authenticated', authenticated, setState);
    };

    const authenticated = state.authenticated;

    return {
        authenticated,
        setAuthenticated
    };
};
