/* eslint-disable  @typescript-eslint/no-explicit-any */
import createStore from './createStore';

// I'm not a fan of "any", but the stateApiFactory here can be anything, depending on what is going to be rendered
// inside the slideout panel. If anyone knows of a type safe way to do this, let me know.
const { StoreProvider: SlideoutPanelStoreProvider, useStore: useSlideoutPanelStore } = createStore<any, any>(
    (): Record<string, unknown> => ({}),
    {}
);

export { SlideoutPanelStoreProvider, useSlideoutPanelStore };