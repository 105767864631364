import React, { lazy, ReactElement, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Constants from '../constants';
import FallBack from './FallBack';

const Financial = lazy(() => import('../../modules/financial/FinancialModule'));

const Routes = (): ReactElement => {
    return (
        <Switch>
            <Route
                path={Constants.Routes.Financial.MODULE}
                render={(): ReactElement =>
                    <Suspense fallback={<FallBack />} >
                        <Financial />
                    </Suspense>
                }
            />
        </Switch>
    );
};

export default Routes;