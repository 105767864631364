import Routes from './routes';
import Permissions from './permissions';
import Localization from './localization';
import Ui from './ui';

const Constants = {
    Routes: { ...Routes },
    Permissions: { ...Permissions },
    Localization: { ...Localization },
    Ui: { ...Ui }
};

export default Constants;
